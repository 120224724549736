import React from 'react';
import styles from './WorkInProgress.module.css';

const WorkInProgress = () => (
  <div className={styles.pageContainer}>
    Under Construction mate, check back later !
  </div>
);

export default WorkInProgress;
